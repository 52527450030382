import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`This component is used to style text using Primer's typographic styles as defined in our design token library, `}<a parentName="p" {...{
        "href": "../primitives"
      }}>{`Primer Primitives`}</a>{`.`}</p>
    <h2>{`Options`}</h2>
    <h3>{`Color`}</h3>
    <p>{`Text can be colored using any of the `}<a parentName="p" {...{
        "href": "../primitives/colors#functional-variables"
      }}>{`functional color tokens`}</a>{`. Only foreground colors should be used.`}</p>
    <h3>{`Other typographic styles`}</h3>
    <p>{`See Primer Primitives `}<a parentName="p" {...{
        "href": "../primitives/typography#styles"
      }}>{`typography`}</a>{` for more details about Primer's typography system.`}</p>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`The component provides no semantic information. It's only function is to provide access to color tokens for styling text content.`}</p>
    <p>{`It is up to the designer / implemented to select the appropriate color token for the text content to ensure that it has sufficient contrast against the background color.`}</p>
    <h3>{`Built-in accessibility features`}</h3>
    <p>{`None.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <p>{`When using the component, ensure that the color token used for the text content has sufficient contrast against the background color.`}</p>
    <h3>{`How to test the component`}</h3>
    <h4>{`Integration tests`}</h4>
    <p>{`None`}</p>
    <h4>{`Component tests`}</h4>
    <ul>
      <li parentName="ul">{`The text output has sufficient contrast against the background color.`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Text" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../primitives"
        }}>{`Primer Primitives`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      